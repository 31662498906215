import { useState } from 'react';
import "./ForgotPassword.css";
import { Card, FloatingLabel, Form, Button } from 'react-bootstrap';
import { useUser } from '../../services/user/user';

const ForgotPassword = ({email, onSend}) => {
  const user = useUser();
  
  const [useremail, setUseremail] = useState(email);

  const handleClick = () => {
    onSend(useremail);
  }

  const handleOnChange = (e) => {
    setUseremail(e.target.value);
  }

  return (
        <Card>
            <Card.Body>
                <Card.Title>Passwort vergessen?</Card.Title>
                <Card.Text>
                    Bitte geben Sie ihre E-Mail Adresse ein:
                </Card.Text>
                <Card.Text>
                    <FloatingLabel label="E-Mail-Adresse" className="mb-3">
                        <Form.Control type="email" placeholder="" value={useremail} onChange={handleOnChange} />
                    </FloatingLabel>
                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%'}}>
                        <Button disabled={user.isLoading()} variant="primary" onClick={handleClick}>Senden</Button>
                    </div>
                </Card.Text>
            </Card.Body>
        </Card>
  );
};

export default ForgotPassword;