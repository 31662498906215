import { useState } from 'react';
import "./TwoFactor.css";
import { Card, FloatingLabel, Form, Button, Nav } from 'react-bootstrap';
import { useUser } from '../../services/user/user';

const TwoFactor = ({title, text, login, type, callback, reSend, showResend}) => {
  const user = useUser();

  const [showError, setShowError] = useState(false);

  const handleLoginClick = (e) => {
    setShowError(false);

    var code = document.getElementById('floatingCode').value;

    if(type == 1){
        user.twoFactor(login, code).then(() => {
            callback();
        }).catch((reason)=>{
            setShowError(true);
        });
    }else if(type == 2){
        user.register(code).then(() => {
            callback();
        }).catch((reason)=>{
            setShowError(true);
        });
    }else{
        user.forgotpassword(login, code).then(() => {
            callback();
        }).catch((reason)=>{
            setShowError(true);
        });
    }
  }

  return (
        <Card>
            <Card.Body>
                <Card.Title>{title}</Card.Title>
                <Card.Text>
                    {text}
                </Card.Text>
                <Card.Text>
                    <FloatingLabel controlId="floatingCode" label="Anmeldecode" className="mb-3">
                        <Form.Control type="code" placeholder="XXXXXX" />
                    </FloatingLabel>
                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%'}}>
                        <Button disabled={user.isLoading()} variant="primary" onClick={handleLoginClick}>Bestätigen</Button>
                        {showResend && (
                            <Nav.Link disabled={user.isLoading()} onClick={() => {reSend(login)}}>Erneut senden</Nav.Link>
                        )}
                    </div>
                </Card.Text>
                {showError && (
                    <Card.Text style={{color:'red'}}>Anmeldecode ist nicht korrekt.</Card.Text>
                )}
            </Card.Body>
        </Card>
  );
};

export default TwoFactor;