import React, { useState } from 'react';
import { Form, Card } from 'react-bootstrap';
import "./FromTo.css";

const FromTo = ({ onChanged }) => {
    const [fromOne, setFromOne] = useState(0);
    const [toOne, setToOne] = useState(0);
    const [fromTwo, setFromTwo] = useState(0);
    const [toTwo, setToTwo] = useState(0);

    const handleFromOne = (n) => {
        setFromOne(parseInt(n.target.value));

        if(fromOne > 0 && toOne > 0 && fromTwo > 0 && toTwo > 0){
            onChanged(fromOne, toOne, fromTwo, toTwo);
        }
    };

    const handleToOne = (n) => {
        setToOne(parseInt(n.target.value));
        
        if(fromOne > 0 && toOne > 0 && fromTwo > 0 && toTwo > 0){
            onChanged(fromOne, toOne, fromTwo, toTwo);
        }
    };

    const handleFromTwo = (n) => {
        setFromTwo(parseInt(n.target.value));

        if(fromOne > 0 && toOne > 0 && fromTwo > 0 && toTwo > 0){
            onChanged(fromOne, toOne, fromTwo, toTwo);
        }
    };

    const handleToTwo = (n) => {
        setToTwo(parseInt(n.target.value));
        
        if(fromOne > 0 && toOne > 0 && fromTwo > 0 && toTwo > 0){
            onChanged(fromOne, toOne, fromTwo, toTwo);
        }
    };

    return (
        <Card>
            <Card.Body>
                <Card.Title>Wähle einen Bereich aus</Card.Title>
                <br />
                <Card.Text>Bereich für die <u>erste</u> Zahl:</Card.Text>
                <Card.Text>
                    <Form.Label>{fromOne}</Form.Label>
                    <Form.Range onChange={handleFromOne} onClick={handleFromOne} />
                </Card.Text>
                <Card.Text>
                    <Form.Label>{toOne}</Form.Label>
                    <Form.Range onChange={handleToOne} onClick={handleToOne} />
                </Card.Text>
                <br />
                <Card.Text>Bereich für die <u>zweite</u> Zahl:</Card.Text>
                <Card.Text>
                    <Form.Label>{fromTwo}</Form.Label>
                    <Form.Range onChange={handleFromTwo} onClick={handleFromTwo} />
                </Card.Text>
                <Card.Text>
                    <Form.Label>{toTwo}</Form.Label>
                    <Form.Range onChange={handleToTwo} onClick={handleToTwo} />
                </Card.Text>
            </Card.Body>
        </Card>
    );
};

export default FromTo;