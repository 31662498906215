import React, { createContext, useState, useContext } from 'react';

import useApiService from "../api/api";

import Login from '../../components/Login/Login';
import TwoFactor from '../../components/TwoFactor/TwoFactor';

import { CgClose } from 'react-icons/cg';
import Register from '../../components/Register/Register';
import Variant from '../../components/Register/Variant';
import Password from '../../components/Register/Password';
import Abo from '../../components/Register/Abo';
import ForgotPassword from '../../components/ForgotPassword/ForgotPassword';
import { useInfo } from '../info/info';

const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
    const info = useInfo();
    const api = useApiService();

    const getUserFromStorage = () =>{
        const storageUser = JSON.parse(localStorage.getItem('user'));

        if(storageUser){
            if((Date.now() - storageUser.time) >= 86400000){
                localStorage.removeItem('user');
            }else{
                return storageUser;
            }
        }

        return null;
    }

    const [user, setUser] = useState(getUserFromStorage());

    const [showIsLoggedIn, setShowIsLoggedIn] = useState(false);
    
    const [registerUser, setRegisterUser] = useState(null);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [modalFadeOutClass, setModalFadeOutClass] = useState(null);

    const openModal = (content) => {
        setModalContent(content);
        setIsModalOpen(true);   
    };

    const closeModal = () => {
        return new Promise((resolve, reject) => {
            setModalFadeOutClass("modal-fade-out");

            setTimeout(() => {
                setIsModalOpen(false);
                setModalContent(null);
                setModalFadeOutClass(null);
                
                setTimeout(()=>{
                    resolve(true);
                }, 100);
            }, 300);
        });
    };
    
    const login = (email, password, opentwofactor) => {
        return new Promise((resolve, reject) => {
            var formData = new FormData();
            formData.append("email", email);
            formData.append("password", password);

            api.postDataFormData("login", formData).then((data) => {
                if(data == true){
                    if(opentwofactor){
                        openTwoFactorModal("Anmelden", "Es wurde ein Anmeldecode an deine E-Mail-Adresse geschickt. Gib diesen Code ein und melde dich an.", email, 1, () => {
                            resolve(true);
                        }, (email) => {
                            login(email, password, false).then(() => {
                                info.showInfo("Code erneut gesendet.");
                            });
                        }, true);
                    }else{
                        resolve(true);
                    }
                }else{
                    reject(false);
                }
            }).catch((error) => {
                console.log("Error: " + error);
                reject(false);
            });
        });
    };

    const twoFactor = (login, code) => {
        return new Promise((resolve, reject) => {
            var formData = new FormData();

            formData.append("email", login);
            formData.append("activationCode", code);

            api.postDataFormData("activationCode", formData).then((data) => {
                if(data === false){
                    setUser(null);
                    reject(false);
                }else{
                    data.time = Date.now();

                    console.log("login:", data);

                    setUser(data);
                    localStorage.setItem('user', JSON.stringify(data));

                    setShowIsLoggedIn(true);

                    closeModal();

                    info.showInfo("Erfolgreich angemeldet.");

                    resolve(true);
                }
            }).catch((error) => {
                console.log("Error: " + error);
                reject(false);
            });
        });
    }

    const sendActivationCode = (name, firstname, lastname, email, street, plz, city, tel, url) =>{
        return new Promise((resolve, reject) => {
            setRegisterUser({
                "name":name,
                "contactFirstName":firstname,
                "contactLastName":lastname,
                "email":email,
                "street":street,
                "plz":plz,
                "city":city,
                "tel":tel,
                "url":url
            });

            const formData = new FormData();
            formData.append("email", email);
    
            api.postDataFormData("sendActivationCode", formData).then((data) => {
                if(data === true || data.trim() === "true"){
                    resolve(true);
                }else{
                    reject(false);
                }
            }).catch((error) => {
                console.log("Error: " + error);
                reject(false);
            });
        });
    }

    const register = (code) =>{
        return new Promise((resolve, reject) => {
            api.postDataJSON("register", registerUser, {activationCode:code}).then((data) => {
                if(data === false){
                    reject(false);
                }else{
                    data.time = Date.now();

                    console.log("register: ", data);

                    setUser(data);
                    localStorage.setItem('user', JSON.stringify(data));

                    setShowIsLoggedIn(false);

                    resolve(true);
                }
            }).catch((error) => {
                console.log("Error: " + error);
                reject(false);
            });
        });
    }

    const forgotpassword = (login, code) => {
        return new Promise((resolve, reject) => {
            var formData = new FormData();

            formData.append("email", login);
            formData.append("activationCode", code);

            api.postDataFormData("activationCode", formData).then((data) => {
                if(data === false){
                    setUser(null);
                    reject(false);
                }else{
                    data.time = Date.now();

                    setUser(data);
                    localStorage.setItem('user', JSON.stringify(data));

                    setShowIsLoggedIn(false);

                    resolve(true);
                }
            }).catch((error) => {
                console.log("Error: " + error);
                reject(false);
            });
        });
    }

    const logout = (showInfo) => {
        return new Promise((resolve, reject) => {
            setUser(null);
            localStorage.removeItem('user');

            if(showInfo){
                info.showInfo(showInfo);
            }

            resolve(true);
        });
    }

    const changePassword = (password) => {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append("userId", user.id);
            formData.append("password", password);
    
            api.postDataFormData("user/changePassword", formData).then((data) => {
                if(data === false){
                    reject(false);
                }else{
                    resolve(true);
                }
            }).catch((error) => {
                console.log("Error: " + error);
                reject(false);
            });

        });
    }

    const sendForgotPassword = (email) => {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append("email", email);
    
            api.postDataFormData("user/forgotPassword", formData).then((data) => {
                if(data === false){
                    reject(false);
                }else{
                    resolve(true);
                }
            }).catch((error) => {
                console.log("Error: " + error);
                reject(false);
            });

        });
    }

    const changeUser = (firstname, lastname) => {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append("userId", user.id);
            formData.append("firstname", firstname);
            formData.append("lastname", lastname);
    
            api.postDataFormData("user/update", formData).then((data) => {
                if(data === false){
                    reject(false);
                }else{
                    user.firstname = firstname;
                    user.lastname = lastname;

                    resolve(true);
                }
            }).catch((error) => {
                console.log("Error: " + error);
                reject(false);
            });

        });
    }

    const isLoggedIn = () => {
        return user !== null && showIsLoggedIn;
    };

    const isLoading = () => {
        return api.loading;
    }

    const getUser = () => {
        return user;
    }

    const openLoginModal = () => {
        closeModal().then(() => {
            openModal(<Login />);
        });
    }

    const openTwoFactorModal = (title, text, login, type, callback, reSend, showResend) => {
        closeModal().then(() => {
            openModal(<TwoFactor title={title} text={text} login={login} type={type} callback={callback} reSend={reSend} showResend={showResend} />);
        });
    }

    const openRegister = () => {
        closeModal().then(() => {
            openModal(<Variant onSelectVariant={handleVariantClick} />);
        });
    }

    const handleVariantClick = (v) => {
        closeModal().then(() => {
            openModal(<Register variant={v} onRegister={handleOnRegister} />);
        });
    }

    const handleOnRegister = (v, registerUser) => {
        if(registerUser.email){
            openTwoFactorModal("Registrierung", "Es wurde ein Registrierungscode an deine E-Mail-Adresse geschickt. Gib diesen Code ein und registriere dich an.", registerUser.email, 2, () => {
                closeModal().then(() => {
                    openModal(<Password variant={v} onSetPassword={openAbo} />);
                });
            }, (email) => {
                sendActivationCode(registerUser.name, registerUser.firstname, registerUser.lastname, registerUser.email, registerUser.street, registerUser.plz, registerUser.city, registerUser.tel, registerUser.url).then(()=>{
                    info.showInfo("Code erneut gesendet.");
                });
            }, false);
        }
    }

    const openAbo = (register, v = (getUser().school.name != null ? 1 : 0)) => {
        closeModal().then(() => {
            openModal(<Abo register={register} variant={v} onSelectAbo={handleAboSelection} />);
        });
    }

    const handleAboSelection = (register) => {
        if(register === true){
            logout("Die Registrierung ist abgeschlossen, du kannst dich nun mit dem Benutzer anmelden.").then(() => {
                closeModal();
            });
        }else{
            closeModal();
        }
    }

    const forgotPassword = (email) => {
        openModal(<ForgotPassword email={email} onSend={handleOnSend} />);
    }

    const handleOnSend = (email) => {
        sendForgotPassword(email).then(() => {
            closeModal().then(() => {
                openTwoFactorModal("Passwort vergessen?", "Es wurde ein Code zum Zurücksetzen des Passworts an deine E-Mail-Adresse geschickt. Gib diesen Code ein und dann kannst du ein neues Passwort vergeben.", email, 3, () => {
                    closeModal().then(() => {
                        openModal(<Password variant={-1} onSetPassword={() => {
                            logout("Das Passwort ist zurückgesetzt. Du kannst dich wieder mit deinem Benutzer anmelden.").then(() => {
                                closeModal();
                            });
                        }} />);
                    });
                }, (email) => {
                    sendForgotPassword(email).then(() => {
                        info.showInfo("Code erneut gesendet.");
                    });
                }, true);
            });
        }).catch(() => {
            closeModal();
        });
    }

    const setUserData = (data) => {
        setUser(data);
    }

    return (
        <UserContext.Provider value={{ isLoggedIn, login, twoFactor, logout, openLoginModal, openRegister, isLoading, getUser, sendActivationCode, register, forgotpassword, changePassword, changeUser, openAbo, setUserData, forgotPassword, openModal, closeModal }}>
          {children}
          {isModalOpen && (
                <div className={modalFadeOutClass ? "modal-overlay " + modalFadeOutClass : "modal-overlay"}>
                    <div className="modal-content">
                        <button className='modal-button' onClick={closeModal}><CgClose/></button>
                        {modalContent}
                    </div>
                </div>
            )}
        </UserContext.Provider>
    );
}

export const useUser = () => {
    return useContext(UserContext);
};