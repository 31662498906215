import { useState } from 'react';
import "./Login.css";
import { Card, FloatingLabel, Form, Button, Nav } from 'react-bootstrap';
import { useUser } from '../../services/user/user';
import { AiFillPicture } from 'react-icons/ai';

const Login = () => {
  const user = useUser();

  const [showError, setShowError] = useState(false);

  const handleLoginClick = (e) => {
    setShowError(false);

    var login = document.getElementById('floatingUser').value;
    var password = document.getElementById('floatingPassword').value;

    user.login(login, password, true).catch((reason)=>{
        setShowError(true);
    });
  }

  const handleOpenForgotPassword = () => {   
    var login = document.getElementById('floatingUser').value;
    user.forgotPassword(login);
  }

  return (
        <Card>
            <Card.Body>
                <Card.Title>Anmeldung</Card.Title>
                <Card.Text>
                    <FloatingLabel controlId="floatingUser" label="Benutzer" className="mb-3">
                        <Form.Control type="user" placeholder="Benutzername" />
                    </FloatingLabel>
                    <FloatingLabel controlId="floatingPassword" label="Passwort" className="mb-3">
                        <Form.Control type="password" placeholder="Passwort" />
                    </FloatingLabel>
                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%'}}>
                        <Button disabled={user.isLoading()} variant="primary" onClick={handleLoginClick}>Anmelden</Button>
                        <Nav.Link disabled={user.isLoading()} onClick={() => {handleOpenForgotPassword()}}>Passwort vergessen?</Nav.Link>
                        <Nav.Link disabled={user.isLoading()} onClick={() => {user.openRegister()}}>Registrieren</Nav.Link>
                    </div>
                </Card.Text>
                {showError && (
                    <Card.Text style={{color:'red'}}>Benutzername oder Passwort ist nicht korrekt</Card.Text>
                )}
            </Card.Body>
        </Card>
  );
};

export default Login;