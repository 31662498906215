import React, { useState, useEffect, useRef } from 'react';
import "./GeneratePDF.css";
import { Row, Card, Button, ListGroup, Nav } from 'react-bootstrap';
import useApiService from '../../services/api/api';
import { RiBookReadFill, RiDeleteBin3Fill, RiDragMove2Line, RiQuoteText } from 'react-icons/ri';
import useUtils from '../../services/utils/utils';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useUser } from '../../services/user/user';
import { BiMinus, BiPlus } from 'react-icons/bi';
import { GrMultiple } from 'react-icons/gr';
import { FaDivide } from 'react-icons/fa';
import { BsDot } from 'react-icons/bs';
import { useInfo } from '../../services/info/info';
import GeneratePDFButton from '../../components/GeneratePDFButton/GeneratePDFButton';

const GeneratePDF = () => {
  const user = useUser();
  const api = useApiService();
  const utils = useUtils();
  const info = useInfo();

  const [items, setItems] = useState([]);
  
  const [selectedItems, setSelectedItems] = useState([]);
  const [draggingItem, setDraggingItem] = useState(null);

  const [selectedNavType, setSelectedNavType] = useState(1);

  const createPDF = async () => {
    let htmlText = "";

    selectedItems.map((item, idx) => {
      const htmlTextPDF = "<div class=\"task\"><div class=\"idx\">"+(idx + 1)+". Aufgabe</div><div class=\"clozetext\">"+item.taskHTML+"</div><div class=\"words\">"+utils.getValues(item.taskPLAIN).join(", ")+"</div></div>";
      htmlText += htmlTextPDF;
    });

    user.openModal((<GeneratePDFButton htmlText={htmlText} onGenerate={() => {
      user.closeModal()
    }} />));
  }

  const handleDragStart = (e, item) => {
    setDraggingItem(item);
    e.dataTransfer.setData('text/plain', '');
  };

  const handleDragEnd = () => {
    setDraggingItem(null);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, targetItem) => {
    //const { draggingItem, items } = state;
    if (!draggingItem) return;

    const currentIndex = selectedItems.indexOf(draggingItem);
    const targetIndex = selectedItems.indexOf(targetItem);

    if (currentIndex !== -1 && targetIndex !== -1) {
      selectedItems.splice(currentIndex, 1);
      selectedItems.splice(targetIndex, 0, draggingItem);
      setSelectedItems(selectedItems);
    }
  };

  const addNewSelectionItem = (newItem) => {
    if(selectedItems.length < 3){
      setSelectedItems(prevItems => [...prevItems, newItem]);
      setItems(prevItems => prevItems.filter(item => item.id !== newItem.id));
    }
  };

  const removeSelectionItem = (removeItem) => {
    setSelectedItems(prevItems => prevItems.filter(item => item.id !== removeItem.id));
    setItems(prevItems => [...prevItems, removeItem]);
  }

  const handleItemClick = (item) => {
    addNewSelectionItem(item);
    document.getElementById("selitems").scrollIntoView({ behavior: 'smooth' });
  };

  const handleRemoveItemClick = (event, item) => {
    removeSelectionItem(item);
  }

  const handleDeleteItemClick = (event, itemToRemove) => {
    user.openModal((
      <Card>
        <Card.Body>
            <Card.Title>Löschen</Card.Title>
            <Card.Text>
              Soll die Aufgabe gelöscht werden?
            </Card.Text>
            <Card.Text>
                <div style={{display:'flex', justifyContent:'left', gap:'20px', alignItems:'center', width:'100%'}}>
                    <Button variant="primary" onClick={() => {
                      api.getData("task/remove/"+itemToRemove.itemID).then((data) => {
                        if(data == true){
                          setItems(items.filter(item => item.itemID !== itemToRemove.itemID));
                          info.showInfo("Aufgabe erfolgreich gelöscht!");
                        }
                      }).catch((error) => {
                          console.log("Error: " + error);
                      }).finally(() => {
                        user.closeModal();
                      });
                    }}>Ja</Button>
                    <Button variant="primary" onClick={() => {
                      user.closeModal()
                    }}>Abbrechen</Button>
                </div>
            </Card.Text>
        </Card.Body>
      </Card>
    ));

    event.stopPropagation();
  }

  const handleNavSelect = (eventKey) => {
    switch(eventKey){
      case "items-1":
        setSelectedNavType(1);
      break;
      case "items-2":
        setSelectedNavType(2);
      break;
      case "items-3":
        setSelectedNavType(3);
      break;
      case "items-4":
        setSelectedNavType(4);
      break;
      case "items-5":
        setSelectedNavType(5);
      break;
      case "items-6":
        setSelectedNavType(6);
      break;
    }
  }

  const addNewItem = (element) => {
    setItems(prevItems => {
      const maxID = Math.max(...prevItems.map(item => item.id), 0);
      const newItemId = maxID + 1;

      element.id = newItemId;

      return [...prevItems, element];
    });
  };

  const getText = (txt) => {
    txt = txt.replace(/\$/g, "");

    if(txt.length > 100){
      txt = txt.substring(0, 100) + " &#8230;";
    }

    return txt;
  }

  const getTitle = (typeid) => {
    if(typeid === 1){
      return "Lückentext";
    }else if(typeid === 2){
      return "Lesetext";
    }else if(typeid === 3){
      return "Addition";
    }else if(typeid === 4){
      return "Subtraktion";
    }else if(typeid === 5){
      return "Multiplikation";
    }else if(typeid === 6){
      return "Division";
    }

    return "";
  }

  const getColor = (typeid) => {
    if(typeid === 1){
      return "success";
    }else if(typeid === 2){
      return "info";
    }else if(typeid === 3){
      return "secondary";
    }else if(typeid === 4){
      return "warning";
    }else if(typeid === 5){
      return "danger";
    }else if(typeid === 6){
      return "dark";
    }

    return "";
  }

  let isInit = false;

  useEffect(() => {
    const init = () => {
      if (items.length === 0 && !isInit) {
        api.getData("task/allTasks/"+user.getUser().id).then((data) => {
          data.forEach(element => {
            element.itemID = element.id;
            addNewItem(element);
          });
        });

        isInit = true;
      }
    };

    init();
  }, []); // Beobachte die Länge von items

  const selectedItemRef = useRef({});

  /*useEffect(() => {
    // Hier kannst du sicherstellen, dass der Scroll nach dem Update stattfindet
    if (selectedItems.length > 0) {
      const lastItemId = selectedItems[selectedItems.length - 1].id;

      if (selectedItemRef.current[lastItemId]) {
        selectedItemRef.current[lastItemId].scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [selectedItems]);*/

  const itemRef = useRef({});

  /*useEffect(() => {
    // Hier kannst du sicherstellen, dass der Scroll nach dem Update stattfindet
    if (items.length > 0) {
      const lastItemId = items[items.length - 1].id;

      if (itemRef.current[lastItemId]) {
        itemRef.current[lastItemId].scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [items]);*/

  return (
    <div> 
      <Row>
          <Card>
              <Card.Body>
                  <Card.Title>Erstelle eine PDF</Card.Title>
                  <Card.Text>
                  Unsere Plattform bietet Ihnen die Möglichkeit, Schulaufgaben gezielt auszuwählen und in einem professionellen Arbeitsblatt-Format als PDF zu generieren. Wählen Sie die gewünschten Aufgaben aus, und lassen Sie sie in einem übersichtlichen und druckfertigen Arbeitsblatt zusammenstellen. Ideal für Lehrer, Schüler und Eltern, die schnell und unkompliziert maßgeschneiderte Arbeitsblätter erstellen möchten. Optimieren Sie Ihre Lernmaterialien und bringen Sie Struktur in Ihre Schulaufgaben!
                  </Card.Text>
              </Card.Body>
          </Card>
      </Row>
      <Row>
          <Card>
              <Card.Body>
                  <Card.Title>Wähle bis zu 3 Aufgaben</Card.Title>
                  <Card.Text>
                    Sie haben die Möglichkeit, bis zu drei Schulaufgaben auszuwählen, die in einem PDF-Dokument zusammengefasst werden. Durch diese Auswahl können Sie gezielt die Aufgaben auswählen, die Ihren Lernzielen entsprechen. Nachdem Sie Ihre Auswahl getroffen haben, wird ein PDF-Dokument erstellt, das alle gewählten Schulaufgaben enthält. Dieses PDF können Sie dann zur weiteren Bearbeitung oder für Ihre Vorbereitung nutzen.
                  </Card.Text>
                  {items.length <= 0 && (
                    <Card.Text>
                      Es sind noch keine Aufgaben vorhanden - über "Aufgaben" können Sie die ersten Aufgaben anlegen. Probieren Sie es doch mal aus.
                    </Card.Text>
                  )}
                  {items.length > 0 && (
                    <>
                      <Card.Text>
                        Mit einem Klick auf die Aufgabe, wird diese in der Liste unten eingefügt.
                      </Card.Text>
                      <Card.Text>
                        <Nav variant="tabs" defaultActiveKey="items-1" onSelect={handleNavSelect}>
                          {items.filter(item => item.typeid === 1).length > 0 && (
                            <Nav.Item>
                              <Nav.Link eventKey="items-1">Lückentexte</Nav.Link>
                            </Nav.Item>
                          )}
                          {items.filter(item => item.typeid === 2).length > 0 && (
                            <Nav.Item>
                              <Nav.Link eventKey="items-2">Lesebeispiele</Nav.Link>
                            </Nav.Item>
                          )}
                          {items.filter(item => item.typeid === 3).length > 0 && (
                            <Nav.Item>
                              <Nav.Link eventKey="items-3">Addition</Nav.Link>
                            </Nav.Item>
                          )}
                          {items.filter(item => item.typeid === 4).length > 0 && (
                            <Nav.Item>
                              <Nav.Link eventKey="items-4">Subtraktion</Nav.Link>
                            </Nav.Item>
                          )}
                          {items.filter(item => item.typeid === 5).length > 0 && (
                            <Nav.Item>
                              <Nav.Link eventKey="items-5">Multiplikation</Nav.Link>
                            </Nav.Item>
                          )}
                          {items.filter(item => item.typeid === 6).length > 0 && (
                            <Nav.Item>
                              <Nav.Link eventKey="items-6">Division</Nav.Link>
                            </Nav.Item>
                          )}
                        </Nav>
                      </Card.Text>
                      <Card.Text>
                        <ListGroup>
                          <TransitionGroup>
                            {items.filter(item => item.typeid === selectedNavType).map((item) => (
                              <CSSTransition
                                key={item.id}
                                timeout={300}  // Zeit für die Animation
                                classNames="list-item"
                              >
                                <ListGroup.Item
                                  action
                                  variant={getColor(item.typeid)}
                                  onClick={() => handleItemClick(item)} // Korrekte Verwendung der Funktion
                                  ref={el => itemRef.current[item.id] = el}
                                >
                                  <p><b>{getTitle(item.typeid)}</b></p>
                                  <p dangerouslySetInnerHTML={{ __html: getText(item.taskPLAIN) }}></p>
                                  <div className='buttons'>
                                    <RiDeleteBin3Fill className='delete-button' style={{cursor:"pointer"}} onClick={(e) => handleDeleteItemClick(e, item)} /> 
                                  </div>
                                </ListGroup.Item>
                              </CSSTransition>
                            ))}
                          </TransitionGroup>
                        </ListGroup>
                      </Card.Text>
                    </>
                  )}
                  
              </Card.Body>
          </Card>
      </Row>
      <Row>
          <Card>
              <Card.Body>
                  <Card.Title>Aufgaben für das Arbeitsblatt</Card.Title>
                  <Card.Text>
                   Folgende Aufgaben werden in entsprechender Reheinfolge auf das Arbeitsblatt eingefügt.
                  </Card.Text>
                  <div id="selitems">
                    {selectedItems.length > 0 && (
                      <div>
                        <Card.Text>
                            <div className="sortable-list">
                            <TransitionGroup>
                              {selectedItems.map((item, index) => (
                                <CSSTransition
                                  key={item.id}
                                  timeout={300}  // Zeit für die Animation
                                  classNames="list-item"
                                >
                                  <div
                                    ref={el => selectedItemRef.current[item.id] = el}
                                    className=
                                        {`item ${item === draggingItem ? 
                                            'dragging' : ''
                                        } ${getColor(item.typeid)}-color`}
                                    draggable="true"
                                    onDragStart={(e) => 
                                        handleDragStart(e, item)}
                                    onDragEnd={handleDragEnd}
                                    onDragOver={handleDragOver}
                                    onDrop={(e) => handleDrop(e, item)}
                                  >
                                    <div className="details">
                                        <span style={{marginRight:"10px"}}>
                                          {item.typeid === 1 && (
                                            <RiQuoteText /> 
                                          )}
                                          {item.typeid === 2 && (
                                            <RiBookReadFill /> 
                                          )}
                                          {item.typeid === 3 && (
                                            <BiPlus /> 
                                          )}
                                          {item.typeid === 4 && (
                                            <BiMinus /> 
                                          )}
                                          {item.typeid === 5 && (
                                            <BsDot /> 
                                          )}
                                          {item.typeid === 6 && (
                                            <FaDivide /> 
                                          )}
                                        </span>
                                        
                                        <span dangerouslySetInnerHTML={{ __html: getText(item.taskPLAIN) }}></span>
                                    </div>
                                    
                                    {/* Use the React icon component */}
                                    <div className='buttons'>
                                      <RiDeleteBin3Fill style={{cursor:"pointer"}} onClick={(e) => handleRemoveItemClick(e, item)} /> 
                                      <RiDragMove2Line /> 
                                    </div>
                                  </div>
                                </CSSTransition>
                              ))}
                              </TransitionGroup>
                            </div>
                      </Card.Text>
                      <Card.Text>
                        Die Aufgaben können noch in der Reihenfolge geändert werden.
                      </Card.Text>
                      <Card.Text>
                        <Button disabled={api.loading} variant="primary" onClick={createPDF}>PDF Generieren</Button>
                      </Card.Text>
                    </div>

                    )}
                    {selectedItems.length === 0 && (
                      <Card.Text>
                        Es sind noch keine Aufgaben ausgewählt.
                      </Card.Text>
                    )}  
                  </div>
              </Card.Body>
          </Card>
      </Row>
    </div>
  );
};

export default GeneratePDF;