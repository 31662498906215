import React, { useState } from 'react';
import { Card, ToggleButton, ToggleButtonGroup, Form } from 'react-bootstrap';
import "./GeneratingMath.css";

const GeneratingMath = ({ math, fromOne, toOne, fromTwo, toTwo, type, onChanged }) => {
    const [count, setCount] = useState(0);

    const handleMath = (count) => {
        math = [];

        for(let i = 0; i < count; i++){
            let first = doRandom(fromOne, toOne);
            let second = doRandom(fromTwo, toTwo);

            switch(type){
                case "+":
                    math.push({
                        first: first,
                        second: second,
                        result: (first + second)
                    });
                break;
                case "-":
                    math.push({
                        first: first,
                        second: second,
                        result: (first - second)
                    });
                break;
                case "*":
                    math.push({
                        first: first,
                        second: second,
                        result: (first * second)
                    });
                break;
                case "%":
                    while(second == 0){
                        second = doRandom(fromTwo, toTwo);
                    }

                    math.push({
                        first: first,
                        second: second,
                        result: (first / second)
                    });
                break;
            }
        }

        onChanged(math);
    }

    const handleCount = (v) => {
        setCount(v);
        handleMath(v);
    }

    const doRandom = (min, max) =>{
        return Math.floor(Math.random() * (parseInt(max) - parseInt(min)) + parseInt(min));
    }

    const getTitle = () => {
        let title = "";

        switch(type){
            case "+":
                title = "Addition";
            break;
            case "-":
                title = "Subtraktion";
            break;
            case "*":
                title = "Multiplikation";
            break;
            case "%":
                title = "Division";
            break;
        }

        return title;
    }

    let itemsCount = [];
    let optionsCount = [];
    for (let number = 1; number <= 20; number++) {
        itemsCount.push(
            <ToggleButton id={"btn_c_"+number} variant="outline-primary" value={number} onClick={() => handleCount(number)}>{number}</ToggleButton>
        );
        
        optionsCount.push(
            <option value={number}>{number}</option>
        );
    }

    return (
        <Card>
        <Card.Body>
            <Card.Title>Generierung</Card.Title>
            <Card.Text>
                    <Form.Label><b>Anzahl {getTitle()}</b></Form.Label>
                    <ToggleButtonGroup className="sentencesToggle" type="radio" name="sentences" defaultValue={-1}>
                        {itemsCount}
                    </ToggleButtonGroup>
                    <Form.Select className="sentencesSelect" value={count} size="sm" onChange={handleCount}>
                        {optionsCount}
                    </Form.Select>
                </Card.Text>
        </Card.Body>
        </Card>
    );
};

export default GeneratingMath;